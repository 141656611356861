import axios from 'axios'

import { firestore } from '$/components/Firebase'
import { backendBaseUrl } from '$/config'

import { getDefaultConfig } from './backend'

import type {
  DocumentData,
  DocumentSnapshot,
  DocumentReference,
} from '@firebase/firestore-types'
import type { User } from '@firebase/auth-types'

export const create = async (user: User): Promise<void> => {
  const { email, uid, displayName, phoneNumber, photoURL } = user
  const config = await getDefaultConfig(user)

  try {
    await axios.post(
      `${backendBaseUrl}/createUser`,
      {
        email,
        id: uid,
        displayName,
        phone: phoneNumber,
        photoUrl: photoURL,
      },
      config,
    )
  } catch (error) {
    console.error(error)
  }
}

export const get = async (id: string): Promise<DocumentSnapshot | undefined> =>
  id ? firestore.collection('users').doc(id).get() : undefined

const getUserDoc = ({ uid }: DocumentData): DocumentReference =>
  firestore.collection('users').doc(uid)

export const update = async (
  user: DocumentData,
  data: Record<string, unknown>,
): Promise<void> => getUserDoc(user).set(data, { merge: true })
